var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrap"},[(_vm.showPage)?_c('div',{staticClass:"box box1"},[_vm._m(0),(!_vm.isAuthentication)?_c('div',{staticClass:"box_hint"},[_c('img',{staticClass:"hint_icon",attrs:{"src":require("@/assets/image/personalCenter/2022050909.png"),"alt":"提示"}}),_c('span',{staticClass:"hint_txt"},[_vm._v("信息将用于核实您的真实身份，与账号唯一绑定，验证成功后不可修改；我们对信息进行严格保密，请放心使用。")])]):_c('div',{staticClass:"box_label"},[_c('img',{attrs:{"src":require("@/assets/image/personalCenter/20220927164630.png"),"alt":""}})]),_c('div',{staticClass:"box_input"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"姓名"}},[(!_vm.isEdit || _vm.isAuthentication)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.name))]):_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              { rules: [
                { required: true, message: '请输入姓名' } ] } ]),expression:"[\n              'name',\n              { rules: [\n                { required: true, message: '请输入姓名' },\n              ] },\n            ]"}],attrs:{"disabled":_vm.isAuthentication,"placeholder":"请输入姓名"}})],1),(!_vm.isEdit || _vm.isAuthentication)?[_c('a-form-item',{attrs:{"label":_vm.identityForm.idType == 1 ? '身份证：': _vm.identityForm.idType == 2 ? '港澳台居住证：': _vm.identityForm.idType == 3 ? '护照' : ''}},[_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.idNo))])]),( _vm.flagnumber != 1 )?_c('a-form-item',{attrs:{"label":"出生日期："}},[(!_vm.isEdit || _vm.isAuthentication)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.birthday))]):_vm._e()]):_vm._e()]:[_c('a-form-item',{staticClass:"item_idType",attrs:{"label":"证件信息："}},[_c('a-form-item',{staticClass:"idType_item"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['idType']),expression:"['idType']"}],staticStyle:{"width":"151px"},attrs:{"disabled":_vm.isAuthentication},on:{"change":_vm.changeIdType}},[_c('a-icon',{staticStyle:{"fontSize":"15px"},attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_vm._l((_vm.identityArray),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2)],1),_c('a-form-item',{staticClass:"idType_item"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'idNo',
                  { rules: _vm.idCardVerify } ]),expression:"[\n                  'idNo',\n                  { rules: idCardVerify },\n                ]"}],attrs:{"disabled":_vm.isAuthentication,"placeholder":"请输入证件号码"},on:{"change":_vm.changeIdNo}})],1)],1),( _vm.flagnumber != 1 )?_c('a-form-item',{attrs:{"label":"出生日期："}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['birthday',{ rules: [{ required: true, message: '请输入出生日期!' }] }]),expression:"['birthday',{ rules: [{ required: true, message: '请输入出生日期!' }] }]"}],attrs:{"format":"YYYY-MM-DD"}})],1):_vm._e()],_c('a-form-item',{attrs:{"label":"性别："}},[(!_vm.isEdit || _vm.isAuthentication)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.sex=='1' ? '男' : _vm.identityForm.sex=='2' ? '女' : ''))]):_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'sex',
              { rules: [{ required: true, message: '请选择性别' }] } ]),expression:"[\n              'sex',\n              { rules: [{ required: true, message: '请选择性别' }] },\n            ]"}],attrs:{"disabled":_vm.sexDisabled || _vm.isAuthentication,"name":"radioGroup"}},_vm._l((_vm.sexArray),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],2)],1)]):_vm._e(),(_vm.showPage)?_c('div',{staticClass:"box box2"},[_vm._m(1),(!_vm.isEdit)?_c('div',{staticClass:"box_edit",on:{"click":_vm.editIdentityAuthen}},[_c('img',{staticClass:"edit_icon",attrs:{"src":require("@/assets/image/personalCenter/2022072701.png"),"alt":"编辑"}}),_c('span',{staticClass:"edit_txt"},[_vm._v("编辑信息")])]):_vm._e(),_c('div',{staticClass:"box_input"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{staticClass:"form_item1",attrs:{"label":"我的身份："}},[(!_vm.isEdit)?[_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.userType==1 ? '家长' : _vm.identityForm.userType==2 ? '从业者（含学生、科研人员等）' : '家长、从业者（含学生、科研人员等）'))])]:[_c('span',{staticClass:"item_info"},[_vm._v("（可选择双重身份）")]),_c('a-row',[_c('a-col',[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'type',
                    { rules: [{ required: true, message: '请选择身份类型' }] } ]),expression:"[\n                    'type',\n                    { rules: [{ required: true, message: '请选择身份类型' }] },\n                  ]"}],on:{"change":_vm.changeType}},[_c('a-checkbox',{attrs:{"value":"1"}},[_vm._v("从业者（含学生、科研人员等）")]),_c('a-checkbox',{attrs:{"value":"2"}},[_vm._v("家长")])],1)],1)],1)]],2),_c('div',{staticClass:"item_wrap"},[_c('a-form-item',{attrs:{"label":"籍贯："}},[(!_vm.isEdit)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.pgAddressName))]):_c('Province',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'pgAddressName',
                { rules: [{ required: false, message: '请选择您的籍贯' }] } ]),expression:"[\n                'pgAddressName',\n                { rules: [{ required: false, message: '请选择您的籍贯' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"pgPlaceholder":"请选择您的籍贯（选填）","addressType":"1","addressValue":_vm.pgAddressValue},on:{"regionCity":_vm.getPgAddressCode}})],1),(_vm.userType==2 || _vm.userType==4)?_c('a-form-item',{attrs:{"label":"所在行业："}},[(!_vm.isEdit)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.industry ? _vm.identityForm.industry+'-'+_vm.identityForm.industrySub : ''))]):_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'industry',
                { rules: [{ required: true, message: '请选择行业' }] } ]),expression:"[\n                'industry',\n                { rules: [{ required: true, message: '请选择行业' }] },\n              ]"}],attrs:{"field-names":{ label: 'name', value: 'code', children: 'children' },"options":_vm.industryArr,"load-data":_vm.getIndustryList1,"placeholder":"请选择行业"},on:{"change":_vm.changeIndustry}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"民族："}},[(!_vm.isEdit)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.nation))]):_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'nationCode',
                { rules: [{ required: false, message: '请选择民族' }] } ]),expression:"[\n                'nationCode',\n                { rules: [{ required: false, message: '请选择民族' }] },\n              ]"}],attrs:{"placeholder":"请选择民族（选填）"},on:{"change":_vm.changeNation}},_vm._l((_vm.nationArr),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(_vm._s(item.name))])}),1)],1),(_vm.userType==2 || _vm.userType==4)?_c('a-form-item',{attrs:{"label":"职业："}},[(!_vm.isEdit)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.occupationName))]):_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'occupationCode',
                { rules: [{ required: true, message: '请选择职业' }] } ]),expression:"[\n                'occupationCode',\n                { rules: [{ required: true, message: '请选择职业' }] },\n              ]"}],attrs:{"placeholder":"请选择职业"},on:{"focus":_vm.focusOccupation,"change":_vm.changeOccupation}},_vm._l((_vm.occupationArr),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e(),_c('a-form-item',{key:"1",attrs:{"label":"学历："}},[(!_vm.isEdit)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.degree))]):_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'degreeCode',
                { rules: [{ required: true, message: '请选择学历' }] } ]),expression:"[\n                'degreeCode',\n                { rules: [{ required: true, message: '请选择学历' }] },\n              ]"}],attrs:{"placeholder":"请选择学历"},on:{"change":_vm.changeDegree}},_vm._l((_vm.degreeArr),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(_vm._s(item.name))])}),1)],1),(_vm.userType==2 || _vm.userType==4)?_c('a-form-item',{attrs:{"label":"工作单位："}},[(!_vm.isEdit)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.work))]):_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'workCom',
                { rules: [{ required: true, message: '请选择工作单位' }] } ]),expression:"[\n                'workCom',\n                { rules: [{ required: true, message: '请选择工作单位' }] },\n              ]"}],attrs:{"mode":"combobox","placeholder":"请选择您的工作单位"},on:{"change":_vm.changeWork}},_vm._l((_vm.workArr),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"所在地："}},[(!_vm.isEdit)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.locationName))]):_c('Province',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'address',
                { rules: [{ required: true, message: '请输入所在地' }] } ]),expression:"[\n                'address',\n                { rules: [{ required: true, message: '请输入所在地' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"pgPlaceholder":"请输入所在地","addressType":"2","addressValue":_vm.addressValue},on:{"regionCity":_vm.getAddressCode}})],1),(_vm.userType==1 || _vm.userType==4)?_c('a-form-item',{attrs:{"label":"孩子生日："}},[(!_vm.isEdit)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.childBirthday))]):_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'childBirthday',
                { rules: [{ required: true, message: '请选择孩子生日' }] } ]),expression:"[\n                'childBirthday',\n                { rules: [{ required: true, message: '请选择孩子生日' }] },\n              ]"}],attrs:{"placeholder":"请选择孩子生日"},on:{"change":_vm.changeBirthday}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"邮箱："}},[(!_vm.isEdit)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.email))]):_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                { rules: [
                  { required: false, message: '请输入邮箱' },
                  { pattern: _vm.$regular.email.reg, message: _vm.$regular.email.msg } ] } ]),expression:"[\n                'email',\n                { rules: [\n                  { required: false, message: '请输入邮箱' },\n                  { pattern: $regular.email.reg, message: $regular.email.msg },\n                ] },\n              ]"}],attrs:{"placeholder":"请输入邮箱（选填）"}})],1),(_vm.userType==2 || _vm.userType==4)?_c('a-form-item',{attrs:{"label":"工作年限："}},[(!_vm.isEdit)?_c('span',{staticClass:"item_con"},[_vm._v(_vm._s(_vm.identityForm.workYears))]):_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'workYears',
              { rules: [
                { required: true, message: '请输入工作年限' } ] } ]),expression:"[\n              'workYears',\n              { rules: [\n                { required: true, message: '请输入工作年限' },\n              ] },\n            ]"}],attrs:{"placeholder":"请输入工作年限","min":0,"max":100000}})],1):_vm._e()],1),(!!_vm.isEdit)?_c('a-form-item',{staticClass:"item_button"},[_c('div',{staticClass:"all-btn-blue",on:{"click":_vm.getIdentityAuthen}},[_vm._v("取消")]),_c('div',{staticClass:"all-btn-small",on:{"click":_vm.check}},[_vm._v("保存"),(_vm.isLoading)?_c('a-icon',{attrs:{"type":"loading"}}):_vm._e()],1)]):_vm._e()],1)],1)]):_vm._e(),(!_vm.showPage)?_c('div',{staticClass:"load_box"},[_c('a-spin',{staticClass:"load",attrs:{"size":"large"}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box_title"},[_c('span',{staticClass:"title_info1"},[_vm._v("实名认证")]),_c('span',{staticClass:"title_info2"},[_vm._v("（认证后无法修改）")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box_title"},[_c('span',{staticClass:"title_info1"},[_vm._v("个人信息")])])}]

export { render, staticRenderFns }